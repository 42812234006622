export const API_KEY = {
    "fyndx1": {
        "cluster_url": "https://api.fyndx1.de",
        "api_key": "64b91be46ae76d6de07faad3",
        "api_secret": "6Avt5mmxWh5L17s"
    },
    "fynd": {
        "cluster_url": "https://api.fynd.com",
        "api_key": "63f893a94adb0bbd79de0344",
        "api_secret": "~qrlshPybVxIeGQ"
    },
    "fyndx5": {
        "cluster_url": "https://api.fyndx5.de",
        "api_key": "63f8926ad139ce26cab2ed11",
        "api_secret": "hwraiI.nFEcCKH~"
    },
}

export const MAX_INTERVAL = 7

export const MARKETPLACE_NAME = "Jiomart"
